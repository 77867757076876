export const base_url = 'http://localhost:8000';

export const admin_authenticate_url = '/';
export const post_blog = '/blog'






