import React from 'react'
import AdminNavbar from '../../../components/admin/AdminNavbar'

export default function Dashbord() {
  return (
    <div>
        <AdminNavbar option={"dashboard"}/>
        <div>Dashbord</div>
    </div>
  )
}
